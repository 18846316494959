<template>
  <div class="flexC page-model">
    <slot class="page-main"></slot>
    <!-- <Article :isFixed="isFixed" /> -->
    <div class="backtop-box">
      <img src="@/assets/img/icon-gift.png" alt />
      <ul class="flexC JustifyContentSA">
        <vue-seamless-scroll
          :data="dataList"
          class="seamless-warp"
          :class-option="classOption"
        >
          <li v-for="(item, index) in dataList" :key="index">
            {{ item.text }}
          </li>
        </vue-seamless-scroll>
      </ul>
      <div class="flex">
        <div class="flexC backtop-top JustifyContentC">
          <div
            class="flex JustifyContentC AlignItemsC"
            style="border-bottom: 1px solid #f9fbfc;"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="电话：15317939367"
              placement="left"
            >
              <i class="el-icon-phone-outline">
                <span>电话咨询</span>
              </i>
            </el-tooltip>
          </div>
          <div class="flex JustifyContentC AlignItemsC" @click="windowOpen">
            <i class="el-icon-service">
              <span>在线咨询</span>
            </i>
          </div>
          <div
            v-if="isFixed"
            class="flex JustifyContentC AlignItemsC"
            style="border-top: 1px solid #f9fbfc;"
          >
            <i class="el-icon-arrow-up" @click="topScroll"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Article from "@/components/Article.vue";
import vueSeamlessScroll from "vue-seamless-scroll";

export default {
  name: "PageModel",
  components: {
    // Article,
    vueSeamlessScroll
  },
  computed: {
    classOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 0, // 0向下 1向上 2向左 3向右
        openWatch: true, //  开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      };
    }
  },
  data() {
    return {
      isFixed: false,
      dataList: [
        { text: "李**, 成为合伙人" },
        { text: "张**, 成为合伙人" },
        { text: "王**, 成为合伙人" },
        { text: "吴**, 成为合伙人" },
        { text: "赵**, 成为合伙人" },
        { text: "钱**, 成为合伙人" },
        { text: "吴**, 成为合伙人" },
        { text: "褚**, 成为合伙人" },
        { text: "李**, 成为合伙人" },
        { text: "欧阳**, 成为合伙人" },
        { text: "牛**, 成为合伙人" }
      ]
    };
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  },
  methods: {
    windowOpen() {
      window.open(
        "http://p.qiao.baidu.com/cps/chat?siteId=16023862&userId=31600645&siteToken=7a48a1953a9bd7d1da04303eb5b691ee&cp=http%3A%2F%2Fpkt.jtgj365.com&cr=http%3A%2F%2Fpkt.jtgj365.com&cw="
      );
    },
    // handleScroll() {
    //   let scrollTop =
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop; // 滚动条偏移量
    //   let offsetTop = document.querySelector("#boxFixed").offsetTop; // 要滚动到顶部吸附的元素的偏移量
    //   this.isFixed = scrollTop > offsetTop ? true : false; // 如果滚动到顶部了，this.isFixed就为true
    // },
    tabFun(index) {
      let _this = this;
      if (index === 3) {
        this.windowOpen();
      } else {
        this.$nextTick(function() {
          let anchor = _this.$el.querySelector("#anchor" + index);
          document.documentElement.scrollTop = anchor.offsetTop;
        });
      }
    },
    topScroll() {
      var timer = null;
      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn() {
        var oTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop =
            oTop - 50;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.page-model {
  width: 100%;
  max-width: 100%;
  min-width: 1200px;
  .page-main {
    margin-top: 80px;
  }
  .seamless-warp {
    width: 100%;
    overflow: hidden;
  }
  .backtop-box {
    position: fixed;
    right: 0%;
    bottom: 40%;
    z-index: 999;
    img {
      margin-bottom: -25px;
    }
    ul {
      width: 96px;
      height: 82px;
      padding: 8px;
      background: #fe4e4e;
      box-shadow: 0px 0px 10px 0px rgba(234, 90, 65, 0.2);
      border-radius: 9px 0px 0px 9px;
      li {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;
        white-space: nowrap;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
    }
    .backtop-top {
      position: absolute;
      width: 110px;
      // height: 150px;
      margin-top: 24px;
      right: 0;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(155, 155, 155, 0.2);
      border-radius: 6px 0px 0px 6px;
      cursor: pointer;
      div {
        padding: 12px 0;
      }
      span {
        margin-left: 8px;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
      }
      i {
        font-size: 20px;
        font-weight: bold;
      }
      i:hover {
        color: #ea5a41ff;
        span {
          color: #ea5a41ff;
        }
      }
    }
  }
}
</style>
