<template>
  <page-model>
    <div class="home">
      <div class="flexC container">
        <div id="anchor0" class="flex main-box1">
          <div class="flexC max-with flex6 JustifyContentC">
            <h1>打造亿万家庭幸福生活新生态</h1>
          </div>
        </div>
        <div class="flex main-box2">
          <div class="flex flex6">
            <div class="flex max-with JustifyContentSA">
              <div class="flexC JustifyContentC">
                <h3>
                  <count-to
                    :startVal="0"
                    :endVal="5000000"
                    :duration="3000"
                  ></count-to>
                  +
                </h3>
                <p>累计使用人数</p>
              </div>
              <div class="flexC JustifyContentC">
                <h3>
                  <count-to
                    :startVal="0"
                    :endVal="30000"
                    :duration="3000"
                  ></count-to>
                  +
                </h3>
                <p>每日使用人数</p>
              </div>
              <div class="flexC JustifyContentC">
                <h3>
                  <count-to
                    :startVal="0"
                    :endVal="50000"
                    :duration="3000"
                  ></count-to>
                  +
                </h3>
                <p>每日使用人数峰值</p>
              </div>
            </div>
          </div>
        </div>
        <div id="anchor1" class="flex main-box3">
          <div class="flexC flex6 max-with JustifyContentSB">
            <h2>您在为以下家政培训问题苦恼？</h2>
            <ul class="flex main-cen-bg JustifyContentSB AlignItemsC">
              <li class="flexC">
                <img src="@/assets/img/icon-peixunchengben.png" alt />
                <h4>培训成本高</h4>
                <p>阿姨工作时间零散，难以 聚集到一起培训</p>
              </li>
              <li class="flexC">
                <img src="@/assets/img/icon-zhiliang.png" alt />
                <h4>培训质量差</h4>
                <p>没有权威、专业的培训 课程</p>
              </li>
              <li class="flexC">
                <img src="@/assets/img/icon-gongzhong.png" alt />
                <h4>培训时间长</h4>
                <p>阿姨工作比较忙，难以长时间参加培训</p>
              </li>
              <li class="flexC">
                <img src="@/assets/img/iocn-neirong.png" alt />
                <h4>培训内容陈旧</h4>
                <p>跟不上市场需求</p>
              </li>
            </ul>
            <div class="main-text">TRAINING PROBLEM</div>
            <h2>科学完善的课程体系</h2>
            <h3>培考通为您提供专业的家政培训课程</h3>
            <div class="swipe-main">
              <el-carousel
                height="387px"
                :interval="5000"
                :autoplay="false"
                arrow="always"
                indicator-position="none"
                @change="getIndex"
              >
                <el-carousel-item
                  v-for="(item, index) in iframeList"
                  :key="index"
                >
                  <div class="swipe-box">
                    <div v-show="!isPlay" class="overlay">
                      <div class="wrapper">
                        <div class="flex icon JustifyContentC AlignItemsC">
                          <van-icon
                            name="play"
                            color="#fff"
                            @click="playVideo"
                          />
                        </div>
                      </div>
                    </div>
                    <video
                      :ref="'videoPlayer' + index"
                      width="100%"
                      height="100%"
                      :src="item.src_mp4"
                      :controls="isPlay"
                      :poster="item.img_src"
                    />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <div class="swipe-text">
          <div class="flexC JustifyContentC">
            <span>{{ title }}</span>
            <p>{{ text }}</p>
          </div>
        </div>
        <div class="flex main-box4">
          <div class="flexC flex6 max-with JustifyContentSB">
            <div class="main-right-radius0"></div>
            <h2>完整场景能力闭环</h2>
            <h3>培考通为您解决家政培训中遇到的问题</h3>
            <ul class="flexC flex1">
              <li class="flex flex1">
                <div class="flex3">
                  <h4 class="flex AlignItemsC">
                    <span></span>
                    学习端
                  </h4>
                  <p>
                    培训内容丰富、专业、系统化；
                    <br />
                    帮您学到更多实用技能，让雇主满意，薪资加倍；
                    <br />
                    <br />
                    <br />
                    上课、做练习、考试，全方位；
                    <br />
                    沉浸式学习体验，帮您把握学习进度，让您学得快、学得好；
                  </p>
                  <div class="flexC qc-img JustifyContentSA AlignItemsC">
                    <img src="@/assets/img/QCXX.png" alt />
                    请扫码体验
                  </div>
                </div>
                <div class="flex1"></div>
                <div class="main-box-bg">
                  <div class="flexC main-right JustifyContentFE AlignItemsC">
                    <div class="main-right-img">
                      <van-swipe :autoplay="3000" indicator-color="#FF6060">
                        <van-swipe-item
                          v-for="(image, index) in imagesXX"
                          :key="index"
                          ><img :src="image"
                        /></van-swipe-item>
                      </van-swipe>
                    </div>
                    <div class="main-right-radius"></div>
                    <div
                      class="flexC main-right-bg JustifyContentFE AlignItemsC"
                    >
                      <div class="main-right-bg2"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="flex flex1">
                <div class="main-box-bg">
                  <div class="flexC main-right JustifyContentFE AlignItemsC">
                    <div class="main-right-radius2"></div>
                    <div class="main-right-radius3"></div>
                    <div class="main-right-img">
                      <van-swipe :autoplay="3000" indicator-color="#FF6060">
                        <van-swipe-item
                          v-for="(image, index) in imagesKH"
                          :key="index"
                          ><img :src="image"
                        /></van-swipe-item>
                      </van-swipe>
                    </div>
                    <div
                      class="flexC main-right-bg JustifyContentFE AlignItemsC"
                    >
                      <div class="main-right-bg2"></div>
                    </div>
                  </div>
                </div>
                <div class="flex1"></div>
                <div class="flex3">
                  <h4 class="flex AlignItemsC">
                    <span></span>
                    考场版
                  </h4>
                  <p>
                    学习效果监测，助您巩固知识点；
                    <br />
                    更有针对性提高自己的薄弱技能；
                    <br />
                    <br />
                    <br />
                    考试通过，即时获取电子证书；
                    <br />
                    可以免费申领纸质证书，得到雇主认可；
                  </p>
                  <div class="flexC qc-img JustifyContentSA AlignItemsC">
                    <img src="@/assets/img/QCKH.png" alt />
                    请扫码体验
                  </div>
                </div>
              </li>
              <li
                class="flex flex1"
                :style="
                  `background: url(${require('@/assets/img/bg3.png')} ); background-size: 100% 100%;`
                "
              >
                <div class="flex3">
                  <h4 class="flex AlignItemsC">
                    <span></span>
                    联创版
                  </h4>
                  <p>
                    定义自己的店铺、价格、海报；
                    <br />
                    邀请自己的合伙人团队；
                    <br />
                    参加TOP1000排行，赢公司股份；
                    <br />
                    <br />
                    学员扫码一键购买；
                    <br />
                    合伙人实时查看订单收益，方便快捷；
                  </p>
                  <div class="flexC qc-img JustifyContentSA AlignItemsC">
                    <img src="@/assets/img/QCLC.png" alt />
                    请扫码体验
                  </div>
                </div>
                <div class="flex1"></div>
                <div class="main-box-bg">
                  <div class="flexC main-right JustifyContentFE AlignItemsC">
                    <div class="main-right-img">
                      <van-swipe :autoplay="3000" indicator-color="#FF6060">
                        <van-swipe-item
                          v-for="(image, index) in imagesLC"
                          :key="index"
                          ><img :src="image"
                        /></van-swipe-item>
                      </van-swipe>
                    </div>
                    <div
                      class="flexC main-right-bg JustifyContentFE AlignItemsC"
                    >
                      <div class="main-right-bg2"></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div id="anchor2" class="flex main-box5">
          <div class="flexC flex6 max-with JustifyContentSB">
            <h2>客户案例</h2>
            <customer-case />
          </div>
        </div>
        <div class="flex main-box6">
          <div class="flex6">
            <div class="flexC max-with JustifyContentSB">
              <h2 style="color:#fff">家庭管家 (上海）科技有限公司</h2>
              <p>
                培考通是家庭管家（上海）科技有限公司倾力打造的家政服务产品。
                <br />
                <br />
                家庭管家（上海）科技有限公司（简称SHFM）成立于2019年5月，是一家以“家庭服务+互联网科技+区块链技术”
                为核心的平台型公司。
                <br />
                <br />
                培考通致力于中国家庭家政服务业提质扩容，提升行业专业化、标准化、职业化服务水平，重塑消费者信任及家政
                服务价值，以立体思维推动家政产业发展。
              </p>
              <ul class="flex JustifyContentSA">
                <li class="flexC AlignItemsC">
                  <img src="@/assets/img/icon-fuwu.png" alt />
                  <span>专属客户经理服务</span>
                </li>
                <li class="flexC AlignItemsC">
                  <img src="@/assets/img/icon-kaidianzhidao.png" alt />
                  <span>全面开店指导</span>
                </li>
                <li class="flexC AlignItemsC">
                  <img src="@/assets/img/icon-baozhang.png" alt />
                  <span>365日全方位保障</span>
                </li>
                <li class="flexC AlignItemsC">
                  <img src="@/assets/img/icon-xuqiuxiangying.png" alt />
                  <span>服务需求响应迅速</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="anchor3" class="flex main-box7">
          <div class="flexC flex6">
            <h2>公司资讯</h2>
            <ul class="flex max-with JustifyContentSA">
              <li
                v-show="
                  index < 3 * current_page && index >= 3 * current_page - 3
                "
                class="flexC JustifyContentSB"
                v-for="(item, index) in dataList"
                :key="index"
                @click="windowOpen(item.http)"
              >
                <img :src="item.img_src" alt />
                <h4>{{ item.title }}</h4>
                <p class="flex JustifyContentSB">
                  {{ item.date }}
                  <span>
                    查看详情
                    <i class="el-icon-right"></i>
                  </span>
                </p>
              </li>
            </ul>
            <el-pagination
              class="flex AlignItemsC pagination"
              background
              :page-size="3"
              layout="prev, pager, next"
              :total="dataList.length"
              @current-change="currentChange"
              @prev-click="prevClick"
              @next-click="nextClick"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- <footer id="anchor4" class="flex1">
      <section class="flex flex1">
        <div class="flexC flex6 max-with">
          <ul class="flex flex4">
            <li class="flexC flex2">
              <h4 class="flexC flex1 JustifyContentC">培考通</h4>
              <div class="flexC flex2 JustifyContentSB">
                <span>首页</span>
                <span>产品与服务</span>
                <span>客户案例</span>
                <span>成为合伙人</span>
                <span>关于我们</span>
              </div>
            </li>
            <li class="flexC flex2">
              <h4 class="flexC flex1 JustifyContentC">联系我们</h4>
              <div class="flexC flex2 JustifyContentSB">
                <p>
                  联系电话
                  <span>15317939367</span>
                </p>
                <p>
                  电子邮件
                  <span>peikaotong@jtgj365.com</span>
                </p>
                <p>
                  公司地址
                  <span>上海市黄浦区鲁班路558号4楼</span>
                </p>
              </div>
            </li>
            <li class="flexC flex2">
              <h4 class="flexC flex1 JustifyContentC">微信公众号</h4>
              <div class="flex2">
                <div class="flex qc-img JustifyContentC AlignItemsC">
                  <img src="@/assets/img/QC.png" alt />
                </div>
              </div>
            </li>
            <li class="flexC flex1">
              <h4 class="flexC flex1 JustifyContentC">友情链接</h4>
              <div
                class="flex2 cursor"
                @click="windowOpen('http://www.jtgj365.com/')"
              >
                家庭管家
              </div>
            </li>
          </ul>
          <h5 class="flex flex1 JustifyContentC">
            Copyright 2020-2022 家庭管家（上海）科技有限公司
            <i>沪ICP备 Copyright© 2019 沪ICP备19033962号-3</i>
          </h5>
        </div>
      </section>
    </footer> -->
  </page-model>
</template>

<script>
// @ is an alias to /src
import PageModel from "@/components/PageModel.vue";
import CustomerCase from "../customer-case.vue";
import countTo from "vue-count-to";

export default {
  name: "Home",
  components: {
    PageModel,
    CustomerCase,
    countTo
  },

  data() {
    return {
      activeName: "second",
      title: "",
      text: "",
      isPlay: false, //播放状态
      nowIndex: 0,
      iframeList: [
        {
          title: "母婴护理(月嫂)课程",
          text:
            "真人宝宝和宝妈出镜展示实际护理过程，辅佐仿真模型来突出操作细节，让学习更有代入感，更高效。",
          src_mp4:
            "https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/3D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/%E6%96%B0%E5%A2%9E1.mp4",
          img_src:'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/3D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/%E6%96%B0%E5%A2%9E1.png'
        },
        {
          title: "育婴护理课程",
          text:
            "以最前沿的“医教养”模式，针对0-36月龄段，规划生活照料、保健与护理、教育和家庭教养指导版块，提升育婴专业能力。",
          src_mp4:
            "https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/3D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/%E6%96%B0%E5%A2%9E2.mp4",
          img_src: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/3D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/%E6%96%B0%E5%A2%9E2.png'
        },
        {
          title: "家政服务课程",
          text:
            "围绕家庭需求频次较高的生活服务，涵盖食衣住行的方方面面，如保洁、营养餐、收纳、洗护熨烫、插花...等，注重培养家政服务人员的动手能力。",
          src_mp4:
            "https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/3D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/%E6%96%B0%E5%A2%9E3.mp4",
          img_src: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/3D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/%E6%96%B0%E5%A2%9E3.png'
        },
        {
          title: "养老护理技能一点通",
          text:
            "以居家养老护理为出发点，着重专业护理的实操技能，满足护理人员和家属对老人的各种照护需求",
          src_mp4:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/3D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/%E6%9B%BF%E6%8D%A24.mp4",
            // "https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/pkt_office/pension.mp4",
          img_src: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/3D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/%E6%9B%BF%E6%8D%A24.png'
        },
        {
          title: "养生餐课程",
          text:
            "种类齐全的家庭营养餐教学，简单易学，实用性十足，更好提升自身的专业度，服务技能增加，涨薪无忧。",
          src_mp4:
            "https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/pkt_office/family.mp4",
          img_src: require("@/assets/img/mp4_img2.png")
        },
        {
          title: "一级收纳师课程",
          text:
            "家庭中的“魔法师”，杂乱无章快速变得井然有序，家务整理无烦恼，工作效率快又好，雇主夸赞停不了。",
          src_mp4:
            "https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/pkt_office/storage.mp4",
          img_src: require("@/assets/img/mp4_img3.png")
        }
      ],
      imagesXX: [
        require("@/assets/img/xx1.png"),
        require("@/assets/img/xx2.png")
      ],
      imagesKH: [
        require("@/assets/img/kh1.png"),
        require("@/assets/img/kh2.png")
      ],
      imagesLC: [
        require("@/assets/img/lc1.png"),
        require("@/assets/img/lc2.png")
      ],

      //分页
      current_page: 1,
      dataList: [
        {
          date: "2020-11-5",
          http: "https://mp.weixin.qq.com/s/IHYwPHLtgUCs5tkIO6_azA",
          title: "【培考通】为家政从业者赋能成效显著，引人民日报关注！",
          img_src: require("@/assets/img/xw1.png"),
          text: "365日全方位保障"
        },
        {
          date: "2020-10-25",
          http: "https://mp.weixin.qq.com/s/RDYMpNtKaouTokL_DGHT9Q",
          title: "家庭管家公司参加安徽首届长三角一体化发展家政节",
          img_src: require("@/assets/img/xw2.png"),
          text: "365日全方位保障"
        },
        {
          date: "2020-10-23",
          http: "https://mp.weixin.qq.com/s/ks1HHEOZt-MRp_XqKwwWYg",
          title: "家庭管家与平安智慧城市签订合作协议，共同推进家政职业技能培训",
          img_src: require("@/assets/img/xw3.png"),
          text: "365日全方位保障"
        }
      ]
    };
  },
  mounted() {
    this.initData(0);
    //初始监听第一个视频
    this.listenVideo();
    // window
    window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  },
  methods: {
    //获取走马灯的索引
    getIndex(nowIndex, oldIndex) {
      // console.log("nowIndex, oldIndex", nowIndex, oldIndex);
      //用户可能在上个视频未播放完毕就切换
      //此时需要暂停上个视频并把时间归零，否则影响对当前视频的监听逻辑
      let myVideo = this.$refs["videoPlayer" + oldIndex];
      // console.log("myVideo", myVideo);
      myVideo[0].pause();
      this.nowIndex = nowIndex;
      this.initData(nowIndex);
      this.listenVideo();
    },
    //监听视频的播放、暂停、播放完毕等事件
    listenVideo() {
      //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
      let myVideo = this.$refs["videoPlayer" + this.nowIndex];
      //监听播放
      myVideo[0].addEventListener("play", () => {
        this.autoplay = false;
        this.isPlay = true;
      });
      // //监听暂停
      // myVideo[0].addEventListener("pause", () => {
      //   this.autoplay = true;
      //   this.isPlay = false;
      // });
      // //监听播放完毕
      // myVideo[0].addEventListener("ended", () => {
      //   //时间归零
      //   myVideo[0].currentTime = 0;
      //   this.autoplay = true;
      //   this.isPlay = false;
      // });
    },
    //播放视频
    playVideo() {
      //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
      let myVideo = this.$refs["videoPlayer" + this.nowIndex];
      myVideo[0].play();
    },
    //暂停视频
    pauseVideo() {
      //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
      let myVideo = this.$refs["videoPlayer" + this.nowIndex];
      myVideo[0].pause();
    },
    initData(index) {
      let { title, text } = this.iframeList.find((v, i) => {
        if (i === index) {
          return v;
        }
      });
      this.title = title;
      this.text = text;
    },
    windowOpen(src) {
      window.open(src);
    },

    currentChange(index) {
      this.current_page = index;
    },
    prevClick(index) {
      this.current_page = index;
    },
    nextClick(index) {
      this.current_page = index;
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  .container {
    width: 100%;
    height: 100%;
  }
  h2 {
    margin-top: 70px;
    font-size: 40px;

    font-weight: 500;
    color: #3a3e4c;
    line-height: 60px;
    letter-spacing: 1px;
    text-align: center;
  }
  h3 {
    font-size: 24px;
    font-weight: 400;
    color: #8c8c95;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: center;
  }
  .main-box1 {
    height: 820px;
    background: url("~@/assets/img/banner.png") rgba(0, 0, 0, 0.1) center no-repeat;
    // background-size: 100% 100%;
    h1 {
      font-size: 60px;
      font-weight: 800;
      color: #ffffff;
      line-height: 90px;
      letter-spacing: 9px;
      text-shadow: 0px 2px 4px #7d7d7d;
    }
  }
  .main-box2 {
    width: 100%;
    height: 250px;
    background: #f8fbfd;
    h3 {
      font-size: 55px;

      font-weight: bold;
      color: #3a3e4c;
      line-height: 82px;
      text-align: center;
    }
    p {
      margin-top: 30px;
      font-size: 20px;

      font-weight: 400;
      color: #666666;
      line-height: 28px;
      text-align: center;
    }
  }
  .main-box3 {
    background: #ffffff;
    .main-cen-bg {
      position: relative;
      width: 1060px;
      height: 440px;
      margin: 0 auto;
      margin-top: 70px;
      margin-bottom: -50px;
      padding: 0 70px;
      background: #ff6060;
      box-shadow: 0px 5px 30px 0px rgba(255, 96, 96, 0.5);
      border-radius: 6px;
      z-index: 1;
      li {
        width: 180px;
        height: 220px;
        padding: 40px 20px;
        background: #ffffff;
        border-radius: 6px;
        text-align: center;
        img {
          width: 70px;
          height: 70px;
          margin: 0 auto;
          border-radius: 50%;
          background: linear-gradient(267deg, #ff9999 0%, #ff6060 100%);
        }
        h4 {
          font-size: 20px;

          font-weight: 500;
          color: #3a3e4c;
          line-height: 60px;
          letter-spacing: 1px;
        }
        p {
          font-size: 16px;

          font-weight: 400;
          color: #666666;
          line-height: 26px;
        }
      }
    }
    .main-text {
      position: relative;
      font-size: 100px;
      font-weight: 800;
      color: rgba(246, 241, 241, 1);
      line-height: 150px;
      letter-spacing: 2px;
      text-align: center;
    }
    .swipe-main {
      margin-top: 70px;
      ::v-deep {
        .el-carousel__container {
          button {
			  width: 65px;
			  height: 65px;
            color: #e6e6e6;
            font-size: 65px;
            background-color: #00000000;
          }
        }
      }
    }
    .swipe-box {
      width: 688px;
      height: 387px;
      margin: 0 auto;
      .overlay {
        position: absolute;
        width: 688px;
        height: 387px;
        background: rgba(0, 0, 0, 0.45);
        box-shadow: 0px 0px 10px 0px #cccccc;
        border-radius: 6px;
        z-index: 99;
      }
      .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .icon {
          width: 120px;
          height: 120px;
          font-size: 80px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.3);
        }
        img {
          width: 120px;
          height: 120px;
        }
      }
      .block {
        width: 120px;
        height: 120px;
        background-color: #ccccccff;
        border-radius: 50%;
      }
      img {
        width: 688px;
        height: 387px;
        margin: 0 auto;
      }
    }
  }
  .swipe-text {
    height: 261px;
    margin-top: -40px;
    background: #fff4f4;
    div {
      width: 688px;
      height: 261px;
      margin: 0 auto;
    }
    span {
      font-size: 32px;

      font-weight: 500;
      color: #3a3e4c;
      line-height: 78px;
      letter-spacing: 1px;
    }
    p {
      font-size: 20px;

      font-weight: 400;
      color: #3a3e4c;
      line-height: 30px;
      letter-spacing: 1px;
    }
  }
  .main-box4 {
    .main-right-radius0 {
      position: absolute;
      width: 200px;
      height: 100px;
      right: 0px;
      opacity: 0.53;
      border: 37px solid #fff;
      border-top: 0;
      border-right: 0;
      border-radius: 0 0 0 100%;
    }
    background: #f8fbfd;
    ul {
      li {
        margin: 100px 20px;
      }
    }
    .main-box-bg {
      width: 600px;
    }
    h4 {
      padding: 24px 0;
      font-size: 32px;

      font-weight: 500;
      color: #3a3e4c;
      line-height: 48px;
      span {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 16px;
        background: #ff6060;
        border-radius: 2px;
        transform: rotate(-45deg);
      }
    }
    p {
      font-size: 20px;

      font-weight: 400;
      color: #3a3e4c;
      line-height: 26px;
      letter-spacing: 1px;
    }
    .qc-img {
      width: 230px;
      height: 264px;
      margin-top: 70px;
      font-size: 20px;

      font-weight: 400;
      color: #3a3e4c;
      line-height: 30px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 1px 10px 0px #f1f3f4;
      border-radius: 6px;
      img {
        width: 202px;
        height: 202px;
      }
    }

    .main-right {
      width: 100%;
      height: 100%;
      position: relative;
      .main-right-bg {
        width: 600px;
        height: 300px;
        position: absolute;
        border-radius: 600px 600px 0 0;
        background: linear-gradient(179deg, #ff6060 0%, #ff9999 100%);
        box-shadow: 0px -2px 6px 0px rgba(249, 123, 123, 0.5);
        .main-right-bg2 {
          width: 550px;
          height: 100px;
          border-radius: 550px 550px 0 0;
          background: linear-gradient(179deg, #ff9999 0%, #ff9999 100%);
          box-shadow: 0px -2px 6px 0px rgba(249, 123, 123, 0.5);
        }
      }
      .main-right-radius {
        position: absolute;
        width: 122px;
        height: 122px;
        top: 40%;
        left: -20px;
        opacity: 0.53;
        border: 10px solid #fff;
        border-radius: 50%;
      }
      .main-right-radius2 {
        position: absolute;
        width: 150px;
        height: 150px;
        top: -20%;
        left: -15px;
        opacity: 0.53;
        border: 20px solid #fff;
        border-radius: 50%;
      }
      .main-right-radius3 {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 0%;
        right: -20px;
        opacity: 0.53;
        border: 2px solid rgba(255, 229, 229, 1);
        border-radius: 50%;
        box-shadow: 0px 1px 8px 0px #ffe5e5;
      }

      .main-right-img {
        position: absolute;
        width: 375px;
        height: 628px;
        padding: 26px 26px 0 26px;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        background: #ffffff;
        z-index: 1;
        ::v-deep .van-swipe__indicator {
          width: 48px;
          height: 6px;
          background: #cccccc;
          border-radius: 4px;
        }
        img {
          width: 376px;
          height: 628px;
        }
      }
    }
  }
  .main-box6 {
    height: 604px;
    background: url("~@/assets/img/WechatIMG6.png") center no-repeat;
    p {
      padding: 45px 60px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 26px;
    }
    ul {
      span {
        margin: 20px 0;
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        line-height: 30px;
      }
    }
  }
  .main-box7 {
    ul {
      margin-top: 70px;
      li {
        width: 368px;
        height: 320px;
        background: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.2);
        border-radius: 6px;
      }
      h4 {
        padding: 0 25px;
        font-size: 18px;

        font-weight: 600;
        color: #3a3e4c;
        line-height: 28px;
        letter-spacing: 1px;
        text-shadow: 0px 0px 20px rgba(204, 204, 204, 0.2);
        white-space: nowrap;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
      p {
        margin-bottom: 25px;
        padding: 0 25px;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        line-height: 19px;
        text-shadow: 0px 0px 20px rgba(204, 204, 204, 0.2);
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #ff6060;
        line-height: 21px;
        text-shadow: 0px 0px 20px rgba(204, 204, 204, 0.2);
      }
    }
    .pagination {
      margin: 72px auto;
      ::v-deep {
        .btn-prev,
        .btn-next {
          min-width: 28px;
          color: #d5d2d2;
          border: 2px solid #d5d2d2;
          border-radius: 50%;
          .el-icon {
            font-size: 16px;
          }
        }

        button:hover {
          color: #000;
          border: 2px solid #000;
        }
        .el-pager {
          li {
            width: 8px;
            min-width: 0;
            height: 8px;
            margin: 0 15px;
            font-size: 0px;
            border-radius: 50%;
            background: #d8d8d8;
          }
          li:not(.disabled).active {
            background: #3a3e4c;
          }
        }
      }
    }

    img {
      width: 368px;
      height: 207px;
      box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.2);
      border-radius: 6px 6px 0px 0px;
    }
  }
}
footer {
  background: #0a061e;
  section {
    height: ceil(606-125px);
    margin: 0 auto;
    ul {
      margin: 0 20px;
      color: #fff;
      li {
        h4 {
          font-size: 16px;

          font-weight: 500;
          line-height: 22px;
        }
      }
      li:nth-child(2) {
        p {
          display: flex;
          flex-direction: column;
          font-size: 12px;

          font-weight: 400;
          color: rgba(255, 255, 255, 0.5);
          line-height: 17px;
        }
        span {
          font-size: 14px;

          font-weight: 500;
          color: #fff;
          line-height: 40px;
        }
      }
      .qc-img {
        width: 143px;
        height: 144px;
        background: #fff;
        img {
          width: 134px;
          height: 134px;
        }
      }
    }
    h5 {
      padding-top: 90px;
      font-size: 12px;

      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      line-height: 18px;
      i {
        padding: 0 16px;
      }
    }
  }
}
</style>
