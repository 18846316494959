<template>
  <div class="customer-case">
    <van-tabs
      v-model="active"
      color="#FF6060"
      title-active-color="#FF6060"
      title-inactive-color="#3E4250"
      line-width="100px"
      line-height="4px"
    >
      <van-tab title="行业合伙人">
        <ul v-if="tabList.length <= 4" class="flex tabs-box JustifyContentSA">
          <li
            class="flexC JustifyContentFE AlignItemsC"
            v-for="(item, index) in tabList"
            :style="`background: url(${item.src})`"
            :key="index"
          >
            <div class="triangle-bottomleft cursor"></div>
            <div class="text cursor">
              <h4>
                <span>{{ item.name }}</span>
              </h4>
              <div class="hover-item" ref="hoverDiv" @mouseleave="leave(index)">
                <p>{{ item.text }}</p>
              </div>
            </div>
          </li>
        </ul>
        <vue-seamless-scroll
          v-else
          :data="tabList"
          class="seamless-warp"
          :class-option="classOption"
        >
          <ul class="flex tabs-box JustifyContentC">
            <li
              class="flexC JustifyContentFE AlignItemsC"
              v-for="(item, index) in tabList"
              :style="`background: url(${item.src})`"
              :key="index"
            >
              <div class="text">
                <h4>
                  <span>{{ item.name }}</span>
                  {{ item.address }}
                </h4>
                <p>{{ item.text }}</p>
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </van-tab>
      <van-tab title="家政公司">
        <div class="tabs-box">
          <div
            v-show="index < 1 * current_page && index >= 1 * current_page - 1"
            class="flex tabs-box-item"
            v-for="(item, index) in dataList"
            :key="index"
          >
            <div class="tabs-left-img">
              <img :src="item.img_src" alt />
            </div>
            <div class="flexC flex1 tabs-right-text JustifyContentSA">
              <h3>{{ item.title }}</h3>
              <p v-html="item.main"></p>
            </div>
          </div>
          <el-pagination
            class="flex pagination JustifyContentFE AlignItemsC"
            background
            :page-size="1"
            layout="prev, pager, next"
            :total="dataList.length"
            @current-change="currentChange"
            @prev-click="prevClick"
            @next-click="nextClick"
          ></el-pagination>
        </div>
      </van-tab>
      <!-- <van-tab title="明星阿姨">
        <ul v-if="tabList2.length <= 4" class="flex tabs-box JustifyContentC">
          <li
            class="flexC JustifyContentFE AlignItemsC"
            v-for="(item, index) in tabList2"
            :style="`background: url(${item.src})`"
            :key="index"
          >
            <div class="text">
              <h4>
                <span>{{ item.name }}</span>
                {{ item.address }}
              </h4>
              <p>{{ item.text }}</p>
            </div>
          </li>
        </ul>
        <vue-seamless-scroll
          v-else
          :data="tabList2"
          class="seamless-warp"
          :class-option="classOption"
        >
          <ul class="flex tabs-box JustifyContentC">
            <li
              class="flexC JustifyContentFE AlignItemsC"
              v-for="(item, index) in tabList2"
              :style="`background: url(${item.src})`"
              :key="index"
            >
              <div class="text">
                <h4>
                  <span>{{ item.name }}</span>
                  {{ item.address }}
                </h4>
                <p>{{ item.text }}</p>
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </van-tab>-->
    </van-tabs>
  </div>
</template>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "customer-case",
  components: { vueSeamlessScroll },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, //  开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      };
    }
  },
  data() {
    return {
      active: 0,
      current_page: 1,

      dataList: [
        {
          title: "如东好苏嫂培训学校",
          img_src: require("@/assets/img/tab-img2.png"),
          main: `如东好苏嫂培训学校2013年经县人社局、县民政局批准设立，始终坚持“以市场需求为导向，以能力培养为中心”的办学指导思想，多层次、多形式、多专业的办学模式。<br>
在偶然间了解到培考通平台后，对培考通平台的专业内容和新颖的合作模式都产生了极大的兴趣。<br>
配合原有的理论知识与技能实操形式，培考通在对学员进行岗前、岗中、晋级、转岗、回炉等职业技能培训等方面，为如东好苏嫂培训学校不仅带了专业的培训内容，还获取了丰厚的利益回报，提升了从业人员整体素质，赢得社会的广泛赞誉。
      `
        },
        {
          title: "上海倩营家政机构",
          img_src: require("@/assets/img/tab-img1.png"),
          main: ` 上海倩营家政机构原是一家主营服务于政企和家庭客户为主线的传统家政机构
，随着互联网的发展，上海倩营家政机构引入互联网培训平台——培考通。<br>
与培考通开启合作后，上海倩营家政机构迅速完成机构转型——机构内部培训引进培考通平台的培训系统。通过培训，家政机构的整体上单率、输送阿姨的质量都有了提升，以前只能在中间赚取差价，现在通过培考通，不但家政阿姨得到实实在在的好处，倩营家政机构也多了一笔稳定可观的收入。
            `
        }
      ],
      tabList: [
        {
          name: "张华",
          address: "(上海区域中心)",
          src: require("@/assets/img/tabs04.png"),
          text:
            "张经理作为金牌家政职业经理人，在服务行业打拼20年，专攻家政服务8年。在接触到培考通后，他凭借着敏锐的市场嗅觉，拼下家政互联网行业的第一波大红利。张经理对于培考通十分信任，正是这份信任，才让张经理不仅稳稳的扎在家政互联网行业，还成为领头羊! "
        },
        {
          name: "陆佩芬",
          address: "(上海区域中心)",
          src: require("@/assets/img/tabs02.png"),
          text:
            "陆老师在加入培考通以前，在单位做人事主管。在过往的经历中，她培养过很多优秀家政员，有着丰富的人脉和资源。接触到培考通后，立马认可培考通的模式，阿姨有了真才实学上单更容易，客户更放心。 "
        },
		{
		  name: "牛海英",
		  address: "(上海区域中心)",
		  src: require("@/assets/img/tabs05.png"),
		  text:
		    "牛海英女士是早期加入培考通平台的个人用户，从最早期的半信半疑，到如今成为培考通最忠实的合作伙伴。加入培考通之后，她不但帮学员在这里收获了专业的知识和证书，也凭着自己出色的能力收入翻倍，收获了更多成就感。 "
		},
        {
          name: "谢华织",
          address: "(上海区域中心)",
          src: require("@/assets/img/tabs041.png"),
          text:
            "去年刚大学毕业的谢华织是培考通合作的伙伴中比较年轻的，她第一次接触培考通后便觉得这个合作模式新颖，也比较稳定，带着一份创业的心思一头钻入了培考通的业务。如今的谢华织在同学中已经小有名气的一员，自己给自己打工是最舒服的。 "
        },
       
      ],
      tabList2: [
        {
          name: "秦晶晶:",
          address: "(上海区域中心)",
          src: require("@/assets/img/tabs05.png"),
          text:
            "秦晶晶沪漂多年，在上海第10个年头选择加入培考通，成为培考通的合作伙伴。秦晶晶现如今已经是家政圈里众人羡慕的对象，不仅仅能够熟练的使用培考通商户端，而且能够根据学员特性来推荐最合适的课程。秦晶晶女士凭借自己出色的能力，获得了颇丰的收益。 "
        }
      ]
    };
  },
  mounted() {},
  methods: {
    leave(index) {
      this.$refs["hoverDiv"][index].scrollTo(0, 0);
    },
    currentChange(index) {
      this.current_page = index;
    },
    prevClick(index) {
      this.current_page = index;
    },
    nextClick(index) {
      this.current_page = index;
    }
  }
};
</script>
<style lang="scss" scoped>
.customer-case {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  .seamless-warp {
    width: 100%;
    overflow: hidden;
  }
  ul {
    li {
      width: 270px;
      height: 390px;
      border-radius: 8px;
      .triangle-bottomleft {
        width: 0;
        height: 0;
        border-bottom: 45px solid #ff6060;
        border-right: 270px solid transparent;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 5px;
      }
      .text {
        padding: 0px 20px 32px 20px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
        background: #ff6060;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      span {
        padding-right: 15px;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 1px;
      }
      .hover-item {
        height: 45px;
        transition: all 0.2s ease-in;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
      .hover-item:hover {
        display: inline-block;
        height: 150px;
        overflow-x: hidden;
        overflow-y: auto;
      }
      .hover-item::-webkit-scrollbar {
        width: 2px;
        border-radius: 10px;
        background: rgba($color: #fff, $alpha: 0.6);
      }
    }
  }
  .tabs-box {
    width: 1200px;
    margin: 88px 0px;
  }
  .tabs-box-item {
    width: 1200px;
    height: 390px;
    margin: 50px 0px;
    background: #f7fafc;
    box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 0.3);
    border-radius: 6px;
    .tabs-left-img {
      width: 410px;
      height: 410px;
      margin-top: -20px;
      background: #d8d8d8;
      box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.2);
      border-radius: 6px 6px 100px 6px;
      overflow: hidden;
      img {
        width: 410px;
        height: 410px;
      }
    }
    .tabs-right-text {
      padding: 40px 20px 32px 60px;
    }
    h3 {
      font-size: 24px;
      font-weight: 500;
      color: #3a3e4c;
      line-height: 36px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #3a3e4c;
      line-height: 30px;
    }
  }
  .pagination {
    margin-top: -90px;
    ::v-deep {
      .btn-prev,
      .btn-next {
        min-width: 28px;
        color: #d5d2d2;
        border: 2px solid #d5d2d2;
        border-radius: 50%;
        .el-icon {
          font-size: 16px;
        }
      }
      button:hover {
        color: #000;
        border: 2px solid #000;
      }
      .el-pager {
        li {
          width: 8px;
          min-width: 0;
          height: 8px;
          margin: 0 15px;
          font-size: 0px;
          border-radius: 50%;
          background: #d8d8d8;
        }
        li:not(.disabled).active {
          background: #3a3e4c;
        }
      }
    }
  }
}
::v-deep {
  .van-tab__text {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 1px;
  }
  .van-tabs__wrap {
    height: 60px !important;
    padding: 0 250px;
  }

  li:not(.disabled).active {
    background: #3a3e4c;
  }
}
</style>
